<template>
  <div class="parent divide-y-4">
    <header class="max-h-3em">
      <slot name="header"></slot>
    </header>
    <main class="coral section p-4">
      <slot></slot>
    </main>
    <footer class="purple section">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<style>
body {
  height: 100dvh !important; /*!important is needed to override when the drawer overrides the height*/
}

#__nuxt {
  height: 100%;
}

.parent {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

main {
  overflow-y: scroll;
}
</style>
